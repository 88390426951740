body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#frame {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

#root > div > div.breakpoint__small-down > div > div.bm-menu-wrap {
  height: 0;
  top: 0;
}

#root
  > div
  > div.breakpoint__small-down
  > div
  > div.bm-menu-wrap
  > div.bm-menu {
  overflow: hidden !important;
}
